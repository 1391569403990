import commerceNoAuthAxiosInstance from "../sevices/commerceNoAuthAxiosInstance"

export const contactUsEmailApi = async (values) => {
    const reqBody = {
        contactMethod: values?.contactMethod,
        organization: values?.organization,
        privacyPolicyConsented: values?.consented,
        supportCategory: values?.supportCategory,
        userEmailAddress: values?.email,
        userFullName: values?.fullname,
        userPhone: values?.phone,
        captcha: {
            userCaptchaResponse: values?.recaptchaToken
        }
    }
    try {
        const res = await commerceNoAuthAxiosInstance({
            url: `/contactUsEmailSend`,
            method: "POST",
            data: reqBody
        })
        return res
    } catch (error) {
        throw error;
    }
}